import React, { useEffect, useState } from 'react'
import SkeletonCom from '../sekeleton/SkeletonCom';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Carousel from 'react-multi-carousel';
import { Typography } from '@mui/material';
import classes from '../QuickHomeRepairs/HomeRepairs.module.css'

const HomeCare = () => {
    const navigate = useNavigate();

    const [allServices, setAllServices] = useState([]);

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 4
        },
        desktop: {
            breakpoint: { max: 3000, min: 1400 },
            items: 4
        },
        laptop: {
            breakpoint: { max: 1399, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 768, min: 1023 },
            items: 2
        },
        largemobile: {
            breakpoint: { max: 767, min: 521 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 520, min: 0 },
            items: 2
        }
    };

    const getServices = async () => {
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/get-products-by-categoryId/656b8abe9f3a2d134bee9396`, { withCredentials: true });
            console.log("woment spa", data);
            setAllServices(data.data);
            // setLoading(false);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getServices();
    }, [])

    return (
        <div className={classes['Card']}>
            <div className={classes['heading']}><Typography variant='h4'>Home Care</Typography></div>
            <Carousel
                removeArrowOnDeviceType={["tablet", "mobile"]}
                swipeable={true}
                draggable={true}
                showDots={false}
                responsive={responsive}
                ssr={true}
                infinite={false}
                keyBoardControl={true}
                customTransition="all 1s"
                transitionDuration={500}
                containerClass="carousel-container"
                itemClass="carousel-item-padding-30-px">
                {
                    allServices.map((item) => (
                        <>
                            <div onClick={() => navigate(`services/${item?.serviceId?._id}`,{ state: { name: item?.serviceId?.name, features: item?.serviceId?.features } })} className={classes['card']} key={item._id}>


                                <div className={classes['single-card']}>
                                    <div className={classes['cardMedia']}>
                                        <SkeletonCom
                                            alt={"service"}
                                            src={`${process.env.REACT_APP_IMAGE_URL}/${item.imageUrl}`}
                                            height={230}
                                        />
                                        {/* <img src={`${process.env.REACT_APP_IMAGE_URL}/${item.imageUrl}`} alt="service" /> */}
                                    </div>
                                </div>

                            </div>
                            <p className={classes['cardname']}><b>{item.name}</b></p>
                            <p className={classes.price}><span style={{ color: 'green' }}>₹{item.offerPrice}</span></p>
                        </>
                    ))
                }

            </Carousel>

        </div>
    )
}

export default HomeCare