import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Wrapper from "../../../Wrapper";
import classes from "../Banner.module.css";
import { useState } from "react";
import useAuthorization from "../../../../hooks/useAuthorization";

const WebProduct = () => {
  const { checkAuthorization } = useAuthorization();
  const [image, setImage] = useState({
    file: null, preview: null,
  });

  const navigate = useNavigate();


  const bannerChangeHandler = (e) => {
    const file = e.target.files[0];

    setImage(() => {
      return {
        file: file,
        preview: URL.createObjectURL(file),
      }
    })
  };

  const uploadImages = async () => {

    const formData = new FormData();
    formData.append("type", "product-banner");
    formData.append("page", "product");
    formData.append("section", "app-productpage");
    formData.append("no_of_images", "single");
    formData.append("img", image.file)

    console.log('imgfile', image.file)


    try {
      const response = await axios.post(
        `${process.env.REACT_APP_CMS_URL}/upload-banners`,
        formData,
        { withCredentials: true }
      );
      console.log(response);
    } catch (err) {
      console.log("ERROR", err.message);
      checkAuthorization(err);
    }
  };

  return (
    <Wrapper>
      <div className={classes.otherBanners}>
        <div className={classes.bannerContainer}>
          <h4>Product Banner</h4>
          {image.preview && (
            <img src={image.preview} alt="banner" />
          )}
          <input
            type="file"
            accept="image/*"
            onChange={(event) => bannerChangeHandler(event)}
            className="mb-2"
          />
        </div>
        <div className="d-flex justify-content-end mx-5 mt-4">
          <button
            type="button"
            className="btn btn-primary"
            onClick={uploadImages}
          >
            Update
          </button>
        </div>
      </div>
    </Wrapper>
  );
};

export default WebProduct;
