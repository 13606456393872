export const contactAddress = [
    // {
    //     city: "Singapore",
    //     address: "8 Burn Rd, Trivex, Singapore 369977"
    // },
    // {
    //     city: "Chandigarh",
    //     address: "Plot No:- 66, Phase-2, Industrial Area, Chandigarh"
    // },
    {
        city: "Darbhanga",
        address: "C/O-SHYAM BIHARI SAH-KHASRA NO.1288 1289, VILL/TOLA/PANCH-TARALAHI BAHADURPUR Darbhanga, Darbhanga , Bihar-846003"
    }
];