import React from "react";
import classes from "./WhyUs.module.css";
import WebsiteWrapper from "../WebsiteWrapper";
import WhyUsComp from "./WhyUsComp";

const WhyUs = () => {
  return (
    <WebsiteWrapper>
      <WhyUsComp />
    </WebsiteWrapper>
  );
};

export default WhyUs;
