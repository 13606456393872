import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Wrapper from "../../../Wrapper";
import DummyImage from "../../../../assets/dummy.png";
import classes from "../Banner.module.css";
import toast from "react-hot-toast";
import useAuthorization from "../../../../hooks/useAuthorization";
import UpdateBannerModal from "../../../../components/update-banner-modal/UpdateBannerModal";

const Home = () => {
  const { checkAuthorization } = useAuthorization();
  const [images, setImages] = useState([
    { bannerName: "hero-banner1", file: null, preview: null },
    { bannerName: "hero-banner2", file: null, preview: null },
    { bannerName: "hero-banner3", file: null, preview: null },
  ]);

  const [banners, setBanners] = useState([
    { bannerName: "banner4", file: null, preview: null },
    { bannerName: "banner5", file: null, preview: null },
  ]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState({
    img: "",
    type: "",
    page: "",
    section: ""
  });

  const navigate = useNavigate();


  const bannerChangeHandler = (e, bannerName) => {
    const file = e.target.files[0];

    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.addEventListener("load", function () {
      console.log(this.result);
      const index = banners.findIndex((banner) => banner.bannerName === bannerName);
      console.log("index", index);
      const instance = [...banners];
      instance.splice(index, 1, { bannerName, file, preview: this.result })
      setBanners(instance);
    });
  };


  const imageChangeHandler = (e, bannerName) => {
    const file = e.target.files[0];
    console.log(file);

    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.addEventListener("load", function () {
      console.log(this.result);
      const index = images.findIndex((banner) => banner.bannerName === bannerName);
      const instance = [...images];
      instance.splice(index, 1, { bannerName, file, preview: this.result })
      setImages(instance);
    });
  };

  const uploadImages = async (type) => {
    //params
    // type='hero-banners','banner1','banner2'

    console.log('type==', type)

    const formDataHero = new FormData();
    const t = type === "banner1" ? 0 : 1;

    if (banners[t].file === null) {
      toast.error("Please select the image");
      return;
    }

    setData({ img: banners[t].file, type: type, page: "home-banners", section: "app-homepage" })

    setIsModalOpen(true);
  };

  const uploadHeroImages = async (type) => {
    console.log(type)
    let filtered = images.find((image) => image.bannerName === type);

    if (!filtered.file) {
      toast.error("Please select the image");
      return;
    }

    setData({ img: filtered.file, type: type, page: "home-hero-banners", section: "app-homepage" })

    setIsModalOpen(true);
  }

  const getBannersFromServer = async () => {
    try {
      const response1 = await axios.get(
        `${process.env.REACT_APP_CMS_URL}/get-banners`,
        {
          params: {
            heroBanners: true,
            page: "home-hero-banners",
            section: "app-homepage",
          },
          withCredentials: true
        }
      );

      const imgInstance = [...images];
      for (let i = 0; i < response1.data.banners.length; i++) {
        const img = response1.data.banners[i].image;
        const bannerName = `hero-banner${i + 1}`;
        const index = images.findIndex(
          (banner) => banner.bannerName === bannerName
        );

        if (index !== -1) {
          imgInstance.splice(index, 1, {
            bannerName: bannerName,
            file: "",
            preview: `${process.env.REACT_APP_IMAGE_URL}/${img}`,
          });
        }
      }
      setImages(imgInstance);

      const response2 = await axios.get(
        `${process.env.REACT_APP_CMS_URL}/get-banners`,
        {
          params: {
            type: "banner1",
            page: "home-banners",
            section: "app-homepage",
          },
          withCredentials: true
        }
      );
      console.log('rs 2 bann', response2)

      const instance = [...banners];
      const index = banners.findIndex((banner) => banner.bannerName === "banner4");
      instance.splice(index, 1, { bannerName: "banner4", file: null, preview: `${process.env.REACT_APP_IMAGE_URL}/${response2.data.banners.image}` })
      setBanners(() => instance);

      const response3 = await axios.get(
        `${process.env.REACT_APP_CMS_URL}/get-banners`,
        {
          params: {
            type: "banner2",
            page: "home-banners",
            section: "app-homepage",
          },
          withCredentials: true
        }
      );
      const index2 = banners.findIndex((banner) => banner.bannerName === "banner5");
      instance.splice(index2, 1, { bannerName: "banner5", file: null, preview: `${process.env.REACT_APP_IMAGE_URL}/${response3.data.banners.image}` })
      setBanners(() => instance);

      console.log("response1", response1);

      console.log(response2);
      console.log('response3', response3);
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    getBannersFromServer();
  }, []);



  return (
    <>
      <Wrapper>
        <div>
          <div className="my-3 mx-5 d-flex justify-content-between">
            <h3>Hero Banners(3)</h3>
          </div>
          <div className={classes.imagesContainer}>
            {images &&
              images.map((img, index) => (
                <div key={index} className={classes.imageWrapper}>
                  {img.bannerName && img.preview && (
                    <img
                      src={img.preview}
                      alt={`i${index + 1}`}
                    />
                  )}
                  <input
                    type="file"
                    name={img.bannerName}
                    accept="image/*"
                    onChange={(event) =>
                      imageChangeHandler(event, img.bannerName)
                    }
                    className="mb-2"
                  />
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => uploadHeroImages(`hero-banner${index + 1}`)}
                  >
                    Update
                  </button>
                </div>
              ))}
          </div>

          <div className={classes.otherBanners}>
            {banners &&
              banners.map((banner, index) => (
                <div key={index} className={classes.bannerContainer}>
                  <h4>{`Banner ${index + 1}`}</h4>

                  {banner.bannerName && banner.preview && (
                    <img
                      src={banner.preview}
                      alt={`banner${index + 1}`}
                    />
                  )}
                  <div className={classes.bannerFooter}>
                    <input
                      type="file"
                      name={banner.bannerName}
                      accept="image/*"
                      onChange={(event) =>
                        bannerChangeHandler(event, banner.bannerName)
                      }
                      className="mb-2"
                    />
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => uploadImages(`banner${index + 1}`)}
                    >
                      Update
                    </button>
                  </div>
                </div>
              ))}
          </div>
          <div className="d-flex justify-content-end mx-5 mt-4"></div>
        </div>
      </Wrapper>
      
      {isModalOpen &&
        <UpdateBannerModal
          getBannersFromServer={getBannersFromServer}
          setIsModalOpen={setIsModalOpen}
          data={data}
        />
      }
    </>
  );
};

export default Home;
